import { Delete } from '@mui/icons-material';
import { Alert, Button, Dialog, DialogContent, IconButton, Snackbar } from '@mui/material';
import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const Messages = ()=>{
    const uri = useSelector(state=>state.uri)
    const [messages, setMessages] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState('')
    const [snackbarstatus, setSnackbarStatus] = useState(false)
    const [delDialog, setDelDialog] = useState(false)
    const [response, setResponse] = useState('')

    useEffect(()=>{
        axios.get(
            `${uri}contact`
        ).then(res=>{
            setIsLoading(false)
            setMessages(res.data.data)
        }).catch(err=>{
            setIsLoading(false)
            err.response ? setError(err.response.data.message) : setError('An error has occured!')
            console.log(err)
        })
    }, [delDialog])

    const handleClose = (event, reason)=>{
        if (reason == 'clickaway') {
            return;
        }

        setSnackbarStatus(false)

    }

    const deleteMessage = (_id)=>{
        setDelDialog(true)
        axios.patch(
            `${uri}contact`,
            {_id}
        ).then(res=>{            
            setDelDialog(false)
            setSnackbarStatus(true)
            setResponse(res.data.message)
        }).catch((err)=>{
            setDelDialog(false)
            setSnackbarStatus(true)
            setResponse('An error occured!')
        })
    }

    return (
        <Fragment>
            <div className='container text-dark mt-0 font-poppins'>
                <p className='fw-bold-0 fs-4 pt-0'>
                    Messages
                </p>
                <p className='fs-6'>
                    Recent messages from the YRH website
                </p>
                {
                    isLoading
                    ?
                    <p className='text-info'>Waiting for server...</p>
                    :
                    messages.length !== 0 && error == ''
                    ?
                    <div className='row w-100 mx-auto my-4'>
                        {
                            messages.map((each, i)=>(
                                <div className='col-md-4 mb-4' key={i}>
                                    <div className='card testimony bg-primary text-white px-3 shadow message-container'>
                                        <div className='d-flex justify-content-between'>
                                            <div>
                                                <IconButton onClick={()=>deleteMessage(each._id)} className='text-danger'><Delete /></IconButton>
                                            </div>
                                            <p className='pt-2'>{each.name}</p>
                                        </div>
                                        <div className='message-body'>                                            
                                            <p className='py-3'>
                                                {each.message}
                                            </p>
                                        </div>
                                        <div className='d-flex justify-content-between pt-3'>
                                            <p>
                                                {new Date(each.created_at).toUTCString()}
                                            </p>                                            
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    :
                    messages.length == 0 && error == ''
                    ?
                    <p className='fs-5 text-primary'>No recent messages...</p>
                    :
                    <p className='fs-5 text-danger'>{error}</p>
                }
            </div>
            {/* Snackbar */}
            <Snackbar open={snackbarstatus} onClose={handleClose} >
                <Alert severity={response.includes('Successfully') ? 'success' : 'error'} onClose={handleClose}>
                    {response}
                </Alert>
            </Snackbar>
            {/* Delete Dialog */}
            <Dialog open={delDialog} PaperProps={{
                style: {
                backgroundColor: 'transparent',
                boxShadow: 'none',
                }
            }}
            >
                <DialogContent>
                    <p>
                        <span className='spinner-border text-white'></span>
                    </p>
                </DialogContent>
            </Dialog>
        </Fragment>
    )
}

export default Messages