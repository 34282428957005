import React, { Fragment, useEffect, useState } from "react";
import Messages from "./Messages";
import NewsLetter from "./NewsLetter";
import NewPublications from "./NewPublications";
import ManageService from "./ManageService";
import ManageImpact from "./ManageImpact";

const Panel = (props)=>{    
    return (
        <div className="container pt-4">
            <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item">
                    <a className="nav-link active" data-bs-toggle="tab" href="#messages">Messages</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#newsletter">NewsLetter</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#newpublication">Publications</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#services">Services</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#impact">Impacts</a>
                </li>
            </ul>

            <div className="tab-content">
                <div id="messages" className="container tab-pane active"><br/>
                    <Messages />
                </div>
                <div id="newsletter" className="container tab-pane fade"><br/>
                    <NewsLetter />
                </div>
                <div id="newpublication" className="container tab-pane fade"><br/>
                    <NewPublications />
                </div>
                <div id="services" className="container tab-pane fade"><br/>
                    <ManageService />
                </div>
                <div id="impact" className="container tab-pane fade"><br/>
                    <ManageImpact />
                </div>
            </div>
        </div>
    )    
}

export default Panel