import React from "react";
import BrandLogo from '../assets/brand_logo.png'
import BrandLogoBlack from '../assets/brand_logo_black.png'
import { Link, useLocation } from "react-router-dom";

const Navbar = ()=>{
    const location = useLocation()    
    return (
        <div>
            <nav data-aos="fade-down" className="navbar navbar-expand-lg bg-body-white text-white">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/">
                        <img src={location.pathname == '/' ? BrandLogo : BrandLogoBlack} />
                    </a>
                    <button className="navbar-toggler text-white bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarText">
                    <ul className="navbar-nav mb-2 mb-lg-0">
                        <li className="nav-item pe-md-4">
                            <Link to={'/about'} className={location.pathname == '/' ? 'nav-link text-white' : 'nav-link text-dark'} aria-current="page" href="#">
                                About
                            </Link>
                        </li>
                        <li className="nav-item px-md-4">
                            <a className={location.pathname == '/' ? 'nav-link text-white' : 'nav-link text-dark'} href="#publications">
                                Publications
                            </a>
                        </li>
                        <li className="nav-item px-md-4">
                            <a className={location.pathname == '/' ? 'nav-link text-white' : 'nav-link text-dark'} href="#impacts">
                                Impacts
                            </a>
                        </li>
                        <li className="nav-item px-md-4">
                            <a className={location.pathname == '/' ? 'nav-link text-white' : 'nav-link text-dark'} href="#services">
                                Services
                            </a>
                        </li>
                        <li className="nav-item ps-md-4">
                            <Link to={'/contact'} className={location.pathname == '/' ? 'nav-link text-white' : 'nav-link text-dark'}>
                                Contact Us
                            </Link>
                        </li>
                    </ul>                    
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Navbar