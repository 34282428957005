import React, { useState } from "react";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ContactUs from '../assets/contact.png'
import { useFormik } from "formik";
import { contactSchema } from "../schemas";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Contact = ()=>{
    const customId = "custom-id-yes";
    const uri = useSelector(state=>state.uri)    
    const [isLoading, setIsLoading] = useState(false)
    const { values, handleBlur, handleChange, handleReset, handleSubmit, errors, touched } = useFormik({
        initialValues: {
            email: '',
            subject: '',
            message: ''
        },
        validationSchema: contactSchema,
        onSubmit: (values)=>{            
            setIsLoading(true)
            axios.post(
                `${uri}contact`,
                values
            ).then(res=>{
                handleReset()
                setIsLoading(false)
                toast.success(res.data.message, {toastId: customId, closeButton: false})
            }).catch(err=>{
                console.log(err)
                toast.error('An error has occured', {toastId: customId, closeButton: false})
                setIsLoading(false)
            })
        }
    })   
    return (
        <div>
            <ToastContainer theme="dark" position="top-right" />
            <div className="container">
                <div className="py-5">
                    <Navbar />
                </div>
                <div className="row w-100 mx-auto mb-5" data-aos="fade">
                    <div className="col-md-4">
                        <img src={ContactUs} className="img-fluid" />
                    </div>
                    <div className="col-md-8">
                        <div className="bg-contact-form rounded py-5 px-4">
                            <p className="fs-2 fw-bold">
                                Contact Us
                            </p>
                            <div className="mb-4">
                                <input name="email" onChange={handleChange} onBlur={handleBlur} value={values.email} className="form-control form-control-lg fs-6 py-3 bg-contact-form" placeholder="Email Address" />
                                {errors.email && touched.email && <div className="text-danger ps-3 fw-bold">{errors.email}</div>}
                            </div>
                            <div className="mb-4">
                                <input name="subject" onChange={handleChange} onBlur={handleBlur} value={values.subject} className="form-control form-control-lg fs-6 py-3 bg-contact-form" placeholder="Subject" />
                                {errors.subject && touched.subject && <div className="text-danger ps-3 fw-bold">{errors.subject}</div>}
                            </div>
                            <div className="mb-4">
                                <textarea name="message" onChange={handleChange} onBlur={handleBlur} value={values.message} className="form-control form-control-lg fs-6 py-3 bg-contact-form" placeholder="Type your Message" />
                                {errors.message && touched.message && <div className="text-danger ps-3 fw-bold">{errors.message}</div>}
                            </div>
                            <button type="submit" onClick={handleSubmit} className="btn btn-primary btn-lg fs-6 px-4">
                                {
                                    isLoading
                                    ?
                                    'Submiting...'
                                    :
                                    'Submit'
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Newsletter />
            <Footer />
        </div>
    )
}

export default Contact