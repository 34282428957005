import React from "react";
import XIcon from '../assets/x-icon.png'
import IgIcon from '../assets/ig-icon.png'
import { Link } from "react-router-dom";

const Footer = ()=>{
    return (
        <div className="bg-footer text-white pt-5">
            <div className="container pt-5">
                <div className="row border-bottom footer-border-color">
                    <div className="col-md-2">
                        <ul className="fs-6">
                            <li>
                                <Link className="text-white" to={'contact'}>Contact Us</Link>
                            </li>
                            <li>
                                <Link className="text-white" to={'about'}>About</Link>
                            </li>
                            <li>
                                <a className="text-white" href="#publications">Publications</a>
                            </li>
                            <li>
                                <a className="text-white" href="#services">Services</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-2">
                       <div className="d-flex px-4 pb-4">
                            <div>
                                <a href="https://x.com/yrhnigeria" target="_blank">
                                    <img src={XIcon} className="img-fluid pe-3" />
                                </a>
                            </div>
                            <div>
                                <a href="https://instagram.com/yrhnigeria" target="_blank">
                                    <img src={IgIcon} className="img-fluid pe-3" />
                                </a>
                            </div>
                       </div>
                    </div>
                </div>
                <div className="py-3">
                    <div className="d-flex justify-content-between flex-md-row flex-column">
                        <div>
                            <div className="d-flex flex-md-row flex-column">
                                <p className="pe-4 fs-6">Privacy Policy</p>
                                <p className="pe-4 fs-6">Terms and Conditions</p>
                                <p className="pe-4 fs-6">Support</p>
                            </div>
                        </div>
                        <div>
                            <p className="fs-6">© Copyright 2024, All Rights Reserved</p>
                        </div>
                    </div>
                </div>
            </div>     
        </div>
    )
}

export default Footer