import React, { useEffect, useState } from "react";
import Conference from '../assets/research_conference.png'
import Program from '../assets/research_program.png'
import Women from '../assets/research_women.png'
import { useSelector } from "react-redux";
import axios from "axios";
import { Skeleton } from "@mui/material";
import moment from 'moment'

const Impact = ()=>{
    const uri = useSelector(state=>state.uri)
    const [impacts, setImpacts] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [arr,] = useState([1,2,3,4])

    useEffect(()=>{
        console.log(('https://res.cloudinary.com/yrhoau23/image/upload/v1727728701/yrh-nigeria/pwiru4lccmzwsokyihmf.jpg').split('upload'))
        axios.get(
            `${uri}impacts`
        ).then(res=>{
            setImpacts(res.data.data)
            setIsLoading(false)
        }).catch(err=>{
            setIsLoading(false)
            // setError(err.message)
        })
    }, [])
    return (
        <div id="impacts">
            <div className="container py-5">
                <p className="text-center fs-1 fw-bold text-impact" data-aos="fade">Impacts</p>
                <div className="row w-100 mx-auto">
                {
                    isLoading
                    ?
                    <div className='container'>
                        <ol className='pb-5'>
                            {
                                arr.map((each, i)=>(
                                    <Skeleton variant='text' className='py-3' />
                                ))
                            }
                        </ol>
                    </div>
                    :
                    impacts.length !== 0
                    ?
                    <div className='container'>
                        <div className='row pb-5'>
                            {
                                impacts.map((each, i)=>(
                                    <div className="col-md-4" key={i}>
                                        <div className="card mb-2">
                                            <img className="card-img-top" src={each.picture.split('upload')[0] + 'upload/w_380,h_290,c_scale'+each.picture.split('upload')[1]} data-aos="fade"/>
                                            <div className="card-body px-0">
                                                <p className="card-title h5 fw-bold">
                                                    {each.caption}
                                                </p>
                                                <p className="card-text text-muted fs-6 mb-0">
                                                    Date: {moment(each.date).format('Do MMMM YYYY')}
                                                </p>
                                                <p className="card-text text-muted fs-6">
                                                    Participants: {each.participants} Persons
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>                
                    :
                    <div className='container'>
                        <p className='fs-5 text-yrh fst-italic pb-5'>
                            Coming Soon
                        </p>
                    </div>
                }
                </div>                    
                {/* <div className="d-flex justify-content-center">
                    <button className="btn btn-outline-primary px-5 btn-lg">
                        View All
                    </button>
                </div> */}
            </div>
        </div>
    )
}

export default Impact