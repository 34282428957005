import React from "react";
import Navbar from "../components/Navbar";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import AboutUs from '../assets/aboutus.png'

const About = ()=>{
    return (
        <div>
            <div className="container">                
                <div className="py-5">
                    <Navbar />
                </div>
                <p className="fs-1 fw-semibold text-impact px-2" data-aos="fade">
                    What We Do
                </p>
                <div className="row px-2">
                    <div className="col-md-6 fs-5" data-aos="fade">
                        <p className="text-justify">
                            Young Researchers Hub (YRH), Nigeria, is a non-governmental research organization focusing on research, innovation, and impact.
                        </p>
                        <p className="text-justify">
                            The organization runs a scientific research academy that aims to train
                            undergraduates, graduates, professionals, and any interested person or organization on the
                            fundamentals of scientific research, thereby promoting the culture of research and scientific innovation.
                        </p>
                        <p className="text-justify">
                            YRH also runs a research fellowship program with the sole objective of conducting research that drives...
                        </p>
                        <button type="button" data-bs-toggle="modal" data-bs-target="#aboutModal" className="btn btn-outline-primary mb-2 px-5 py-2">
                            Read All
                        </button>
                    </div>
                    <div className="col-md-6">
                        <img src={AboutUs } data-aos="zoom-in" className="img-fluid" />
                    </div>
                </div>
            </div>
            <Newsletter />
            <Footer />

            <div className="modal fade" id="aboutModal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 class="modal-title">What We Do</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                        <p className="text-justify">
                            Young Researchers Hub (YRH), Nigeria, is a non-governmental research organization focusing on research, innovation, and impact. 
                        </p>
                        <p className="text-justify">
                            The organization runs a scientific research academy that aims to train undergraduates, graduates, professionals, and any interested person or organization on the fundamentals of scientific research, thereby promoting the culture of research and scientific innovation. 
                        </p>
                        <p className="text-justify">
                            YRH also runs a research fellowship program with the sole objective of conducting research that drives evidence-based policies. 
                        </p>
                        <p className="text-justify">
                            The organization also oversees the Young Researchers&#39; Hub&#39;s affairs in all the Nigerian campuses (universities). 
                        </p>
                        <p className="text-justify">
                            YRH Nigeria is the convener/organizer of the annual <strong>STUDENT RESEARCHERS&#39; CONFERENCE #SRC</strong>, which held its maiden edition at Obafemi Awolowo University (check our timeline).
                        </p>
                        <p className="text-justify">
                            SRC is an annual scientific conference to promote the culture of research and innovation among undergraduates in Nigeria.
                        </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About