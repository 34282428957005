import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogContent, Typography } from "@mui/material";
import Fellowship from '../assets/research_fellowship.png'
import Training from '../assets/research_training.png'
import Academy from '../assets/research_academy.png'
import { useSelector } from "react-redux";
import axios from "axios";

const Advert = ()=>{
    const uri = useSelector(state=>state.uri)
    const [url, setUrl] = useState('')
    const [type, setType] = useState('')
    useEffect(()=>{
        axios.get(`${uri}ads`).then(res=>{
            setType(res.data.ads.ads)
            let service = res.data.service.find(each=>each.type == res.data.ads.ads)            
            if (service !== undefined) {
                setUrl(service.url)
                setOpen(true)
            } else {
                setUrl('')
            }            
        }).catch((err)=>{
            console.log('No Ads', err)
        })
    }, [])

    const [open, setOpen] = useState(false);    
    const handleClose = () => setOpen(false);
    return (
        <div>
            <Dialog 
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogContent className="p-5">
                    <div className="d-flex justify-content-center">
                        {
                            type == 'Fellowship'
                            ?
                            <img className="img-service" src={Fellowship} />
                            :
                            type == 'Training'
                            ?
                            <img className="img-service" src={Training} />
                            :
                            <img className="img-service" src={Academy} />
                        }
                    </div>
                    <p className="fs-2 fw-bold text-center">
                        A Call for YRH Research {type}
                    </p>
                        {
                            type == 'Fellowship'
                            ?
                            <p className="card-text text-muted text-center fs-5">
                                This program is a 3-month intensive research program to give hands-on research experience on the selected candidates.
                            </p> 
                            :
                            type == 'Training'
                            ?
                            <p className="card-text text-muted fs-5 text-center">
                                This program is exclusively for members and not for outsiders. It's aim is just to improve the research skill of every member
                            </p>
                            :
                            <p className="card-text text-muted fs-5 text-center">
                                A six month intensive Research training and mentorship program for both undergraduate and graduate
                            </p>
                        }
                    <div className="d-flex justify-content-center">
                        <Button onClick={()=>window.open(url)} className="bg-primary px-5 text-white py-2 fw-bold fs-5">
                            Join Now
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>   
        </div>
    )
}

export default Advert