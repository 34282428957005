import React, { useEffect, useState } from "react";
import Fellowship from '../assets/research_fellowship.png'
import Training from '../assets/research_training.png'
import Academy from '../assets/research_academy.png'
import axios from "axios";
import { useSelector } from "react-redux";

const Services = ()=>{
    const uri = useSelector(state=>state.uri)
    const [services, setServices] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const joinNow = (url)=>{
        window.open(url)
    }

    useEffect(()=>{
        axios.get(
            `${uri}services`
        ).then(res=>{
            setIsLoading(false)
            setServices(res.data.data)            
        }).catch(err=>{  
            setIsLoading(false) 
            console.log(err)     
            // setError(err.message)
        })
    }, [])
    return (
        <div id="services" className="services py-2">
            <div className="container py-5">
                <p className="text-center fs-1 fw-bold text-impact" data-aos="fade">Our Services</p>
                <div className="row w-100 mx-auto">
                    <div className="col-md-4">
                        <div className="card mb-4 py-5 rounded-lg" data-aos="zoom-in">
                            <div className="d-flex justify-content-center">
                                <img className="img-service" src={Fellowship} />
                            </div>
                            <div className="card-body text-center">
                                <p className="card-title h5 fw-bold">
                                    YRH Research Fellowship
                                </p>
                                <p className="card-text text-muted fs-6 mb-0">
                                    This program is a 3-month intensive research program to give hands-on research experience on the selected candidates.
                                </p>                                
                                <div className="d-flex justify-content-center">
                                    {
                                        isLoading
                                        ?
                                        <button className="btn btn-outline-primary px-5 mt-4">
                                            <span className="spinner-border spinner-border-sm"></span>
                                        </button>
                                        :
                                        services[0] && services[0].active && !isLoading
                                        ?
                                        <button onClick={()=>joinNow(services[0].url)} className="btn btn-outline-primary px-5 mt-4">
                                            Join Now
                                        </button>
                                        :
                                        <button className="btn btn-light px-5 mt-4">
                                            Closed
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card mb-4 py-5 rounded-lg" data-aos="zoom-in">
                            <div className="d-flex justify-content-center">
                                <img className="img-service" src={Training} />
                            </div>
                            <div className="card-body text-center">
                                <p className="card-title h5 fw-bold">
                                    YRH Research Training
                                </p>
                                <p className="card-text text-muted fs-6 mb-0">
                                    This program is exclusively for members and not for outsiders. It's aim is just to improve the research skill of every member
                                </p>                                
                                <div className="d-flex justify-content-center">
                                    {
                                        isLoading
                                        ?
                                        <button className="btn btn-outline-primary px-5 mt-4">
                                            <span className="spinner-border spinner-border-sm"></span>
                                        </button>
                                        :
                                        services[1] && services[1].active && !isLoading
                                        ?
                                        <button onClick={()=>joinNow(services[1].url)} className="btn btn-outline-primary px-5 mt-4">
                                            Join Now
                                        </button>
                                        :
                                        <button className="btn btn-light px-5 mt-4">
                                            Closed
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card mb-4 py-5 rounded-lg" data-aos="zoom-in">
                            <div className="d-flex justify-content-center">
                                <img className="img-service" src={Academy} />
                            </div>
                            <div className="card-body text-center">
                                <p className="card-title h5 fw-bold">
                                    YRH Research Academy
                                </p>
                                <p className="card-text text-muted fs-6 mb-0">
                                    A six month intensive Research training and mentorship program for both undergraduate and graduate
                                </p>                                
                                <div className="d-flex justify-content-center">
                                    {
                                        isLoading
                                        ?
                                        <button className="btn btn-outline-primary px-5 mt-4">
                                            <span className="spinner-border spinner-border-sm"></span>
                                        </button>
                                        :
                                        services[2] && services[2].active && !isLoading
                                        ?
                                        <button onClick={()=>joinNow(services[2].url)} className="btn btn-outline-primary px-5 mt-4">
                                            Join Now
                                        </button>
                                        :
                                        <button className="btn btn-light px-5 mt-4">
                                            Closed
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    )
}

export default Services