import React, { useState } from 'react'
import NewsBox from '../assets/newsbox.png'
import { useFormik } from 'formik'
import { newsletterSchema } from '../schemas'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { Alert, Snackbar } from '@mui/material'

const Newsletter = ()=>{    
    const [snackbarstatus, setSnackbarStatus] = useState(false)
    const uri = useSelector(state=>state.uri)    
    const [response, setResponse] = useState('')
    const [isSubscribing, setIsSubscribing] = useState(false)
    const { values, handleBlur, handleChange, handleReset, handleSubmit, errors, touched } = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: newsletterSchema,
        onSubmit: (values)=>{                        
            setIsSubscribing(true)                   
            axios.put(
                `${uri}newsletter`,
                values
            ).then(res=>{        
                handleReset()
                setIsSubscribing(false)
                setSnackbarStatus(true)
                setResponse(res.data.message) 
            }).catch(err=>{
                console.log(err)
                setSnackbarStatus(true)
                setIsSubscribing(false)
                setResponse(err.response.data.message ? err.response.data.message : err.message)
            })
        }
    })
    const handleClose = (event, reason)=>{
        if (reason == 'clickaway') {
            return;
        }

        setSnackbarStatus(false)

    }
    return (
        <div className='newsletter text-white py-5' data-aos="fade">
            <div className='d-flex justify-content-center'>
                <img src={NewsBox} className='img-fluid' data-aos="fade" />
            </div>
            <p className='text-center pt-4 fs-1 fw-normal' data-aos="fade">
                Join our Newsletter
            </p>
            <p className='text-center fs-' data-aos="fade">
                Join our newsletter for exclusive updates, new features, and expert tips to boost your research.
            </p>
            <div className='d-flex justify-content-center'>
                <div className='col-md-5'>
                    <div className="input-group mb-3 px-md-0 px-4">
                        <input value={values.email} onBlur={handleBlur} onChange={handleChange} name='email' type="email" className="form-control form-control-lg fs-6" placeholder="Type your Email Address" aria-label="Recipient's username" aria-describedby="button-addon2" />
                        <button disabled={isSubscribing} className="btn btn-newsletter" type="button" id="button-addon2" onClick={handleSubmit}>                            
                            {
                                isSubscribing
                                ?
                                'Subscribing...'
                                :
                                'Subscribe Now'
                            }
                        </button>
                    </div>
                    {errors.email && touched.email && <div className="text-danger ps-3 fw-bold">{errors.email}</div>}
                </div>        
            </div>  
            <Snackbar autoHideDuration={3000} open={snackbarstatus} onClose={handleClose} >
                <Alert severity={response.includes('ing') ? 'success' : 'error'} onClose={handleClose}>
                    {response}
                </Alert>
            </Snackbar>          
        </div>
    )
}

export default Newsletter