import React, { Fragment, useEffect, useState } from 'react';
import { Alert, Button, Dialog, DialogContent, IconButton, Snackbar } from '@mui/material';
import { Delete } from '@mui/icons-material';
import axios from 'axios';
import { useFormik } from 'formik';
import { publicationSchema } from '../schemas';
import { useSelector } from 'react-redux';

const NewPublications = ()=>{
    const uri = useSelector(state=>state.uri)
    const [publications, setPublications] = useState([])
    const [dialog, setDialog] = useState(false)
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [delDialog, setDelDialog] = useState(false)
    const [snackbarstatus, setSnackbarStatus] = useState(false)
    const [response, setResponse] = useState('')

    const {handleChange, handleBlur, handleSubmit, errors, touched} = useFormik({
        initialValues: {
            title: '',
            authors: '',
            link: ''            
        },
        validationSchema: publicationSchema,
        onSubmit: (values)=>{
            setIsSubmitting(true)
            axios.post(
                `${uri}publications`,
                values
            ).then(res=>{
                setIsSubmitting(false)
                fetchData()
                setDialog(false)
            }).catch(err=>{
                setIsSubmitting(false)
                console.log(err)
            })
        }
    })

    useEffect(()=>{
        fetchData()
    }, [])

    const fetchData = ()=>{
        axios.get(
            `${uri}publications`
        ).then(res=>{
            setPublications(res.data.data)
            setIsLoading(false)
        }).catch(err=>{
            setIsLoading(false)
            setError(err.message)
        })
    }
    const handleClose = (event, reason)=>{
        if (reason == 'clickaway') {
            return;
        }

        setSnackbarStatus(false)

    }
    const deletePublication = (_id)=>{
        setDelDialog(true)
        axios.patch(
            `${uri}publications`,
            {_id}
        ).then(res=>{
            fetchData()
            setDelDialog(false)
            setSnackbarStatus(true)
            setResponse(res.data.message)
        }).catch((err)=>{
            setDelDialog(false)
            setSnackbarStatus(true)
            setResponse('An error occured!')
        })
    }

    return (
        <Fragment>
            <div className='container font-poppins mt-0 text-dark'>
                <p className='fw-bold-0 fs-4 text-dark pt-0'>
                    Publications
                </p>
                <p className='fs-6 text-danger'>
                    You can't add more than five (5) publications
                </p>
                <Button onClick={()=>setDialog(true)} className='bg-primary text-white font-poppins fw-bold rounded-0 mb-3'>
                    Add
                </Button>
                {
                    isLoading
                    ?
                    <p className='text-info'>Waiting for server...</p>
                    :
                    publications.length !== 0
                    ?
                    <div className='row w-100 mx-auto my-4'>
                        {
                            publications.map((each, i)=>(
                                <div key={i} className='col-md-4 mb-4'>
                                    <div className='card publication border-primary text-primary px-3 pb-2 shadow-lg'>
                                        <p className='pt-3 card-title fw-bold title'>{each.title.slice(0, 80)}{each.title.length <= 80 ? '' : '...'}</p>                                        
                                        <p className='authors'>
                                            {each.authors.slice(0, 90)}{each.authors.length <= 90 ? '' : '...'}                                            
                                        </p>
                                        <div className='d-flex justify-content-between'>
                                            <Button onClick={()=>window.open(each.link)} className='bg-primary rounded-0 text-white fw-bold'>View </Button>
                                            <IconButton onClick={()=>deletePublication(each._id)} className='text-danger'><Delete /></IconButton>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    :
                    publications.length == 0 && error == ''
                    ?
                    <p className='text-primary fs-5'>Nothing is here...</p>
                    :
                    <p className='text-danger fs-5'>{error}</p>
                }
            </div>
            {/* Upload Modal */}
            <Dialog open={dialog} maxWidth={'xs'} fullWidth={true} className='font-poppins' >
                <DialogContent>
                    <p className='fs-6'>Add a Publication </p>
                    <p className='fs-7'>Links/Url must start with <span className='text-danger'>**https or http</span></p>
                    <div className='my-4'>
                        <textarea name='authors' onChange={handleChange} onBlur={handleBlur} placeholder="List the authors" className={errors.authors && touched.authors ? 'form-control is-invalid' : 'form-control'} />
                    </div>
                    <div className='my-4'>
                        <input type='text' onChange={handleChange} onBlur={handleBlur} name='title' className={errors.title && touched.title ? 'form-control is-invalid' : 'form-control'} placeholder='Title of the Publication' />
                    </div>
                    <div className='my-4'>
                        <input type='text' className={errors.link && touched.link ? 'form-control is-invalid' : 'form-control'} name='link' onChange={handleChange} onBlur={handleBlur} placeholder='Publication Link/Url' />
                    </div>                    
                    <Button disabled={isSubmitting} onClick={handleSubmit} className={isSubmitting ? 'border border-primary text-primary rounded-0 mx-1' : 'bg-primary text-white rounded-0 mx-1'}>
                        {
                            isSubmitting
                            ?
                            'Adding...'
                            :
                            'Add'
                        }
                    </Button>
                    <Button disabled={isSubmitting} onClick={()=>setDialog(false)} className='bg-light text-dark rounded-0 mx-1'>
                        Cancel
                    </Button>
                </DialogContent>
            </Dialog>
            {/* Snackbar */}
            <Snackbar open={snackbarstatus} onClose={handleClose} >
                <Alert severity={response.includes('Successfully') ? 'success' : 'error'} onClose={handleClose}>
                    {response}
                </Alert>
            </Snackbar>
            {/* delete modal */}
            <Dialog open={delDialog} PaperProps={{
                style: {
                backgroundColor: 'transparent',
                boxShadow: 'none',
                }
            }}
            >
                <DialogContent>
                    <p>
                        <span className='spinner-border text-white'></span>
                    </p>
                </DialogContent>
            </Dialog>
        </Fragment>
    )
}

export default NewPublications