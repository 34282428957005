import React, { useEffect, useState } from "react";
import EndMalaria from '../assets/end_malaria.png'
import PolicyToward from '../assets/policy_towards.png'
import AfricaCapacity from '../assets/africa_capacity.png'
import { useSelector } from "react-redux";
import axios from "axios";
import { Skeleton } from "@mui/material";

const Publications = ()=>{
    const uri = useSelector(state=>state.uri)
    const [publications, setPublications] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [arr,] = useState([1,2,3,4])

    useEffect(()=>{
        axios.get(
            `${uri}publications`
        ).then(res=>{
            setPublications(res.data.data)
            setIsLoading(false)
        }).catch(err=>{
            setIsLoading(false)
            // setError(err.message)
        })
    }, [])
    const openPaper = (url)=>{
        window.open(url)
    }
    return (
        <div id="publications">
            <div className="container py-5">
                <p className="text-impact fs-1 fw-bold text-center" data-aos="fade">
                    Publications
                </p>
                {
                    isLoading
                    ?
                    <div className='container'>
                        <ol className='pb-5'>
                            {
                                arr.map((each, i)=>(
                                    <Skeleton variant='text' className='py-3' />
                                ))
                            }
                        </ol>
                    </div>
                    :
                    publications.length !== 0
                    ?
                    <div className='container'>
                        <ol className='pb-5'>
                            {
                                publications.map((each, i)=>(
                                    <div key={i}>
                                        <div className="">                    
                                            <div onClick={()=>openPaper(each.link)} className="row mb-4 w-100 mx-auto border box-shadow" data-aos="fade">                                                
                                                <div className="col-md-8">
                                                    <p className="fw-bold fs-4 mb-0 pt-md-5">
                                                        {each.title}
                                                    </p>
                                                    <p className="text-muted fs-6">
                                                        {each.authors}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>                                                        
                                    </div>
                                ))
                            }
                        </ol>
                    </div>                
                    :
                    <div className='container'>
                        <p className='fs-5 text-yrh fst-italic pb-5'>
                            No recent publications at the moment, check back later...
                        </p>
                    </div>
                }                
                {/* <div className="d-flex justify-content-center">
                    <button className="btn btn-outline-primary px-5 btn-lg">
                        View All
                    </button>
                </div> */}
            </div>
        </div>
    )
}

export default Publications