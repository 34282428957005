import React from "react";
import Footer from "../components/Footer";
import Newsletter from "../components/Newsletter";
import Impact from "../components/Impact";
import Services from "../components/Services";
import Publications from "../components/Publications";
import Header from "../components/Header";
import Advert from "../components/Advert";

const Home = ()=>{
    return (
        <div>
            <Header />
            <Publications />
            <Services />
            <Impact />
            <Newsletter />
            <Footer />
            <Advert />
        </div>
    )
}

export default Home