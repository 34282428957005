import React, { Fragment, useEffect, useState } from 'react';
import { Alert, Button, Dialog, DialogContent, Snackbar } from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import ServicesAds from './ServicesAds';
import { serviceSchema } from '../schemas';

const ManageService = ()=>{
    const uri = useSelector(state=>state.uri)        
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [isSubmitting, setIsSubmitting] = useState(false)    
    const [services, setServices] = useState([])
    const [snackbarstatus, setSnackbarStatus] = useState(false)
    const [delDialog, setDelDialog] = useState(false)
    const [response, setResponse] = useState('')

    const {handleChange, handleBlur, handleReset, handleSubmit, errors, touched, values} = useFormik({
        initialValues: {
            type: '',
            url: '',                  
        },
        validationSchema: serviceSchema,
        onSubmit: (values)=>{            
            setIsSubmitting(true)
            axios.post(
                `${uri}services`,
                values
            ).then(res=>{                
                handleReset()
                setIsSubmitting(false)
                fetchService()   
                setSnackbarStatus(true)
                setResponse(res.data.message)             
            }).catch(err=>{
                setIsSubmitting(false)
                console.log(err)
                setSnackbarStatus(true)
                setResponse(err.response.data.message ? err.response.data.message : err.message)
            })
        }
    })

    const handleClose = (event, reason)=>{
        if (reason == 'clickaway') {
            return;
        }

        setSnackbarStatus(false)

    }

    useEffect(()=>{
        fetchService()
    }, [])

    const fetchService = ()=>{
        axios.get(
            `${uri}services`
        ).then(res=>{            
            setServices(res.data.data)
            setIsLoading(false)
        }).catch(err=>{
            setIsLoading(false)
            setError(err.message)
        })
    }    

    return (
        <Fragment>
            <div className='container font-poppins mt-0 text-dark'>
                <p className='fw-bold-0 fs-4 text-dark pt-0'>
                    YRH Services
                </p>
                <p className='fs-6 text-dark'>
                    Provide the Google Form Link to each of the services
                </p>   
                <div className='my-4'>
                    <select value={values.type} name='type' onChange={handleChange} onBlur={handleBlur} className={errors.type && touched.type ? 'is-invalid form-select' : 'form-select'} >
                        <option value={''}>Select Services</option>
                        <option value={'Fellowship'}>Fellowship</option>
                        <option value={'Training'}>Training</option>
                        <option value={'Academy'}>Academy</option>
                    </select>
                </div>
                <div className='my-4'>
                    <input name="url" value={values.url} type='text' onChange={handleChange} onBlur={handleBlur} className={errors.url && touched.url ? 'is-invalid form-control' : 'form-control'} placeholder='Google Form Link' />
                </div>         
                <Button disabled={isSubmitting} onClick={handleSubmit} className={isSubmitting ? 'fw-bold border border-primary text-primary rounded-0 mx-1' : 'fw-bold bg-primary text-white rounded-0 mx-1'}>
                    {
                        isSubmitting
                        ?
                        'Saving...'
                        :
                        'Save'
                    }
                </Button>  
                <hr />
                <ServicesAds services={services} fetchService={fetchService} />  
            </div> 
            <Snackbar autoHideDuration={3000} open={snackbarstatus} onClose={handleClose} >
                <Alert severity={response.toLowerCase().includes('successfully') ? 'success' : 'error'} onClose={handleClose}>
                    {response}
                </Alert>
            </Snackbar>
            {/* Delete Dialog */}
            <Dialog open={delDialog} PaperProps={{
                style: {
                backgroundColor: 'transparent',
                boxShadow: 'none',
                }
            }}
            >
                <DialogContent>
                    <p>
                        <span className='spinner-border text-white'></span>
                    </p>
                </DialogContent>
            </Dialog>       
        </Fragment>
    )
}

export default ManageService