import { Alert, Backdrop, Button, Dialog, DialogContent, IconButton, Snackbar } from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import { Delete } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { impactsSchema } from '../schemas';

const ManageImpact = ()=>{
    const uri = useSelector(state=>state.uri)
    const [impacts, setImpacts] = useState([1,2,3,4,5])
    const [uploadDialog, setUploadDialog] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState('')
    const [fileSize, setFileSize] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [snackbarstatus, setSnackbarStatus] = useState(false)
    const [response, setResponse] = useState('')
    const [delDialog, setDelDialog] = useState(false)

    const {handleBlur, handleChange, handleReset, handleSubmit, errors, touched, values} = useFormik({
        initialValues: {
            picture: '',
            caption: '',
            date: '',
            participants: ''
        },
        validationSchema: impactsSchema,
        onSubmit: (values)=>{            
            setIsSubmitting(true)
            axios.post(
                `${uri}impacts`,
                values
            ).then(res=>{
                handleReset()
                setFileSize('')
                setIsSubmitting(false)
                setUploadDialog(false)
            }).catch(err=>{            
                setSnackbarStatus(true)
                setIsSubmitting(false)
                err.response.data.message ? setResponse(err.response.data.message) : setResponse('Something went wrong..')
            })
        }
    })

    const handleClose = (event, reason)=>{
        if (reason == 'clickaway') {
            return;
        }

        setSnackbarStatus(false)

    }
    useEffect(()=>{
        axios.get(
            `${uri}impacts`
        ).then(res=>{
            setImpacts(res.data.data)
            setIsLoading(false)
        }).catch(err=>{
            setError(err.message)
            setIsLoading(false)
        })
    }, [isSubmitting, delDialog])

    const pickPicture = ()=>{
        document.getElementById('picture').click()
    }
    const processPhoto = (e)=>{
        let file = e.target.files[0]
        if (file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/jpeg') {
            JSON.stringify(file.size).length >= 7 ? setFileSize(`${Math.ceil(file.size/1000000)}MB`) : setFileSize(`${Math.ceil(file.size/1000)}KB`)
            const fs = new FileReader()
            fs.readAsDataURL(file)
            fs.onload = ()=>{
                values.picture = fs.result
            }
        } else {
            alert('File format not supported')
        }
    }
    const deleteEvent = (_id)=>{
        setDelDialog(true)
        axios.patch(
            `${uri}impacts`,
            {_id}
        ).then(res=>{
            setDelDialog(false)
            setSnackbarStatus(true)
            setResponse(res.data.message)
        }).catch(()=>{
            setDelDialog(false)
            setSnackbarStatus(true)
            setResponse('An error occured!')
        })
    }

    return (
        <Fragment>
            <div className='container font-poppins mt-0 text-dark'>
                <p className='fw-bold-0 fs-4 text-dark pt-0'>
                    Impacts Highlights
                </p>
                <p className='fs-6'>
                    Upload an image that shows an Highlights of the Impact the Hub had made
                </p>
                <Button onClick={()=>setUploadDialog(true)} className='bg-primary text-white font-poppins fw-bold rounded-0 mb-3'>
                    Upload
                </Button>
                {
                    isLoading
                    ?
                    <p className='text-info'>Waiting for server...</p>
                    :
                    impacts.length !== 0 && error == ''
                    ?
                    <div className='row'>
                        {
                            impacts.map((each, i)=>(
                                <div className='col-md-4 mb-4' key={i}>
                                    <div className='event-container'>
                                        <img src={each.picture.split('upload')[0] + 'upload/w_380,h_290,c_scale'+each.picture.split('upload')[1]} className='img-fluid event-pic' />
                                        <div className='text-center text-white word-break fw-bold-0 event-caption'>
                                            <span className=''>
                                                {each.caption}
                                            </span>
                                            <span className='d-md-block d-none'>
                                            {each.participants} participants
                                            </span>
                                        </div>
                                        <div className='event-action'>
                                            <IconButton onClick={()=>deleteEvent(each._id)}>
                                                <Delete className='text-danger' />
                                            </IconButton>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    :
                    impacts.length == 0 && error == ''
                    ?
                    <p className='fs-5 text-yrh'>Nothing is here...</p>
                    :
                    <p className='fs-5 text-danger'>{error}</p>
                }
            </div>
            {/* Upload Modal */}
            <Dialog open={uploadDialog} maxWidth={'xs'} fullWidth={true} className='font-poppins' >
                <DialogContent className='px-md-4 px-3'>
                    <p className='fs-6'>Upload an event <span className='fs-7 text-danger'>*jpg, jpeg & png only</span></p>
                    <div className='my-4'>
                        <div className='d-flex'>
                            <Button onClick={pickPicture} className='bg-primary text-white fw-bold font-poppins'>A Picture</Button> <span className='fs-5 px-2 fw-bold-0 text-yrh'>- {fileSize}</span>
                        </div>
                        <input id='picture' type='file' className='d-none form-control' onChange={processPhoto} />
                    </div>
                    <div className='my-4'>
                        <input placeholder='Write a caption' name='caption' onChange={handleChange} onBlur={handleBlur} className={errors.caption && touched.caption ? 'is-invalid form-control' : 'form-control'} />
                    </div>
                    <div className='my-4'>
                        <input type='text' placeholder='How many participated?' name='participants' onChange={handleChange} onBlur={handleBlur} className={errors.participants && touched.participants ? 'is-invalid form-control' : 'form-control'} />
                    </div>
                    <div className='my-4'>
                        <input type="date" placeholder='Date' name='date' onChange={handleChange} onBlur={handleBlur} className={errors.date && touched.date ? 'is-invalid form-control' : 'form-control'} />
                    </div>
                    <Button onClick={handleSubmit} className={isSubmitting ? 'fw-bold border border-primary text-primary rounded-0 mx-1' : 'fw-bold bg-primary text-white rounded-0 mx-1'}>
                        {
                            isSubmitting
                            ?
                            'Uploading...'
                            :
                            'Upload'
                        }
                    </Button>
                    <Button onClick={()=>setUploadDialog(false)} className='fw-bold bg-light text-dark mx-1 rounded-0'>
                        Cancel
                    </Button>
                </DialogContent>
            </Dialog>
            {/* Snackbar */}
            <Snackbar open={snackbarstatus} onClose={handleClose} >
                <Alert severity={response.includes('Successfully') ? 'success' : 'error'} onClose={handleClose}>
                    {response}
                </Alert>
            </Snackbar>
            {/* delete modal */}
            <Dialog open={delDialog} PaperProps={{
                style: {
                backgroundColor: 'transparent',
                boxShadow: 'none',
                }
            }}
            >
                <DialogContent>
                    <p>
                        <span className='spinner-border text-white'></span>
                    </p>
                </DialogContent>
            </Dialog>
        </Fragment>
    )
}

export default ManageImpact