import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Aos from 'aos';
import { useEffect } from 'react';
import 'aos/dist/aos.css'
import NotFound from './pages/NotFound';
import Panel from './admin/Panel';

function App() {
  useEffect(()=>{
    Aos.init({duration: 2000})
  })
  return (
    <BrowserRouter>
      <Routes>
        <Route 
          path='/'
          element={<Home />}
        />
        <Route 
          path='/about'
          element={<About />}
        />
        <Route 
          path='/contact'
          element={<Contact />}
        />
        <Route 
          path='*'
          element={<NotFound />}
        />
        <Route 
          path='/panel' 
          element={<Panel />} 
        />   
      </Routes>
    </BrowserRouter>
  );
}

export default App;
