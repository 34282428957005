import React from "react";
import Arrow from '../assets/arrow.png'
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";

const Header = ()=>{
    const navigate = useNavigate()
    return (
        <div>
            <div className="banner" data-aos="fade">
                <div className="container">
                    <div className="py-5">
                        <Navbar />
                    </div>
                    <div className="pb-5" data-aos="zoom-in">
                        <p className="fs-1 text-white mb-0 pb-0">
                            Empowering
                        </p>
                        <p className="fs-1 text-white mb-0 pb-0">
                            <strong>Change</strong> Through
                        </p>
                        <p className="fs-1 text-white mb-0 pb-0">
                            <span className="fw-bold text-yellow">Research,</span>
                        </p>
                        <p className="fs-1 text-primary fw-bold mb-0 pb-0">
                            <span>Innovation</span>
                        </p>
                        <p className="fs-1 text-white mb-0 pb-0">
                            <span>and <strong className="text-yellow">Impact</strong></span>
                        </p>
                    </div>
                    <div className="d-flex" data-aos="fade">
                        <div className="pe-md-5 pt-md-5 pb-5">
                            <button onClick={()=>navigate('/about')} className="btn btn-primary rounded-lg py-3 px-5">
                                Explore Now
                            </button>
                        </div>
                        <div className="d-md-block d-none">
                            <img src={Arrow} className="arrow-header" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header