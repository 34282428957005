import React, { Fragment } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const NotFound = ()=>{
    const navigate = useNavigate()
    return (
        <Fragment>
            <div className="container pt-5">
                <Navbar />
            </div>
            <div className='container p-5 mb-5' data-aos='fade-in'>
                <p className='fw-bold-0 fs-3 pt-md-5 pt-3'>
                    No Results Found
                </p>
                <p className='text-yrh fs-5'>
                    The page you requested could not be found. Try refining your search, or use the navigation above to locate the path.
                </p>
                <Button onClick={()=>navigate('/')} className='bg-primary text-white fw-bold px-5 py-3 font-poppins'>
                    Take me Home
                </Button>
            </div>
            <Footer />
        </Fragment>
    )
}

export default NotFound