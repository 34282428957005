import React, { Fragment, useEffect, useState } from 'react';
import { Alert, Button, Dialog, DialogContent, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, Snackbar, Switch } from '@mui/material';
import axios from 'axios';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Delete } from '@mui/icons-material';

const ServicesAds = (props)=>{
    const { services, fetchService } = props
    const uri = useSelector(state=>state.uri)
    const [snackbarstatus, setSnackbarStatus] = useState(false)
    const [delDialog, setDialog] = useState(false)
    const [response, setResponse] = useState('')    
    const [ads, setAds] = useState('')
    const [_id, setId] = useState('')

    useEffect(()=>{
        axios.get(`${uri}ads`).then(res=>{
            setAds(res.data.ads.ads)
            setId(res.data.ads._id)
        }).catch((err)=>{
            console.log('Service Ads could not fetch', err)
        })
    }, [response])

    const handleClose = (event, reason)=>{
        if (reason == 'clickaway') {
            return;
        }

        setSnackbarStatus(false)

    }

    const handleChange = (event, _id)=>{
        setDialog(true)
        axios.put(
            `${uri}services`,
            {_id, active: event.target.checked}
        ).then(res=>{  
            fetchService()         
            setDialog(false)
            setSnackbarStatus(true)
            setResponse(res.data.message)
        }).catch((err)=>{
            setDialog(false)
            setSnackbarStatus(true)
            setResponse('An error occured!')
        })        
    }

    const deleteService = (_id)=>{
        setDialog(true)
        axios.patch(
            `${uri}services`,
            {_id}
        ).then(res=>{   
            fetchService()         
            setDialog(false)            
            setSnackbarStatus(true)
            setResponse(res.data.message)
        }).catch((err)=>{
            setDialog(false)
            setSnackbarStatus(true)
            setResponse('An error occured!')
        })
    }

    const openAdverts = ()=>{
        let payload = { _id, ads }
        setDialog(true)
        axios.post(
            `${uri}ads`,
            payload
        ).then(res=>{                     
            setDialog(false)
            setSnackbarStatus(true)
            setResponse(`A Call for YRH Nigeria ${ads} is now made available on the website.`)
        }).catch((err)=>{
            setDialog(false)
            setSnackbarStatus(true)
            setResponse('An error occured!')
        })
    }

    return (
        <Fragment>
            <div className='container'>
                <div className='border rounded-lg p-5 mb-4'>
                    <p className='fs-5'>
                        Available Services
                    </p>
                    <div className='row w-100'>
                        {
                            services.map((each, i)=>(
                                <div className='col-md-4' key={i}>
                                    <p>
                                        YRH Nigeria {each.type}
                                    </p>
                                    <div className='d-flex justify-content-between'>
                                        <div>
                                            <FormGroup>
                                                <FormControlLabel control={<Switch onChange={(e)=>handleChange(e, each._id)} checked={each.active} />} label='Active' />
                                            </FormGroup>                                            
                                        </div>
                                        <div>
                                            <Button onClick={()=>deleteService(each._id)}>
                                                <Delete />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        {
                            services.length == 0 &&
                            <p className='text-danger fs-6'>
                                You have not saved any services yet
                            </p>
                        }
                    </div>
                    <p className='fs-5 pt-4'>
                       Available Ads <span className='text-danger fs-6'>Select a service to open an ads</span>
                    </p>
                    <FormControl>                        
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(e)=>setAds(e.target.value)}
                            value={ads}
                        >
                            <FormControlLabel value="Fellowship" control={<Radio />} label="Fellowship" />
                            <FormControlLabel value="Training" control={<Radio />} label="Training" />
                            <FormControlLabel value="Academy" control={<Radio />} label="Academy" />
                            <FormControlLabel
                            value="None"                            
                            control={<Radio />}
                            label="None"
                            />
                        </RadioGroup>
                    </FormControl>
                    <br />
                    <Button onClick={openAdverts} className='' endIcon={<AdsClickIcon />}>
                       Open
                    </Button>                    
                </div>
            </div>

            <Snackbar autoHideDuration={3000} open={snackbarstatus} onClose={handleClose} >
                <Alert severity={response.includes('ll') ? 'success' : 'error'} onClose={handleClose}>
                    {response}
                </Alert>
            </Snackbar>
            {/* Delete Dialog */}
            <Dialog open={delDialog} PaperProps={{
                style: {
                backgroundColor: 'transparent',
                boxShadow: 'none',
                }
            }}
            >
                <DialogContent>
                    <p>
                        <span className='spinner-border text-white'></span>
                    </p>
                </DialogContent>
            </Dialog>   
        </Fragment>
    )
}

export default ServicesAds