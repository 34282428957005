import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import RichTextEditor from 'react-rte';

const NewsLetter = (props)=>{
    const uri = useSelector(state=>state.uri)
    const [editorLetter, setEditorLetter] = useState('')
    const [subject, setSubject] = useState('')
    const [letter, setLetter] = useState(RichTextEditor.createEmptyValue())
    const [isOpen, setIsOpen] = useState(false)
    const [response, setResponse] = useState('')
    const [respDialog, setRespDialog] = useState(false)
    const [isSending, setIsSending] = useState(false)
    const [info, setInfo] = useState('')
    const [sendingDialog, setSendingDialog] = useState(false)
    const [count, setCount] = useState('')

    useEffect(()=>{
        axios.get(
            `${uri}newsletter`
        ).then(res=>{
            setCount(res.data.count)
        }).catch((err)=>{
            console.log(err)
        })
    }, [])

    const handleChange = (value)=>{
        setLetter(value)
        setEditorLetter(value.toString('html'))       
    }
    const onClickSend = ()=>{
        if (subject !== '' && editorLetter !== '') {
            setInfo('send')
            setIsOpen(true)
        } else {
            alert('All fields are required')
        }
    }
    const onClickContinue = ()=>{
        setIsOpen(false)
        setSendingDialog(true)
        handleSubmit()
    }
    const handleSubmit = ()=>{
        const payload = { html: editorLetter, subject }
        console.log(payload)
        setIsSending(true)        
        axios.post(
            `${uri}newsletter`,
            payload
        ).then(res=>{
            setSubject('')
            setLetter(RichTextEditor.createEmptyValue())
            setIsSending(false)
            setRespDialog(true) 
            setSendingDialog(false)               
            setResponse(res.data.message)
        }).catch(err=>{
            setSendingDialog(false)
            setIsSending(false)            
            err.response ? alert(err.response.data.message) : alert('An error occurred')
        })        
    }
    return (
        <Fragment>
            <div className='container font-poppins mt-0 text-dark'>
                <p className='fw-bold-0 fs-4 text-dark pt-0'>
                    Newsletter
                </p>
                <p className='fs-6 text-dark'>
                    Write and construct the newsletter in the text-editor below
                </p>
                <p className='fs-6 text-danger'>
                    Caution: Send this newsletter only with a desktop size device.
                </p>
                <div className='my-4'>
                    <input onChange={(e)=>setSubject(e.target.value)} value={subject} className='form-control' placeholder='Subject of the NewsLetter' />
                </div>
                <RichTextEditor onChange={handleChange} value={letter} />
                <div className='my-2'>
                    <Button disabled={isSending} className='bg-primary text-white fw-bold font-poppins me-2 rounded-0' onClick={onClickSend}>
                        Send
                    </Button>
                    <Button disabled={editorLetter == ''} className='btn-light fw-bold text-primary font-poppins ms-2 rounded-0' onClick={()=>{
                        setInfo('')
                        setIsOpen(true)
                    }}>
                        Preview
                    </Button>
                </div>
            </div>
            {/* Preview Dialog */}
            <Dialog open={isOpen} onClose={()=>setIsOpen(false)} >
                <DialogContent className=''>
                    {
                        info == 'send'
                        ?
                        <>
                            <p className='text-danger font-poppins'>
                                This newsletter will be sent to all {count !== '' && `the ${count}`} subscribers
                            </p>
                            <div>
                                <Button onClick={onClickContinue} className='font-poppins bg-primary text-white rounded-0 fw-bold-0'>Continue</Button>
                                <Button onClick={()=>setIsOpen(false)} className='btn-light text-dark font-poppins'>Go Back</Button>
                            </div>
                        </>
                        :
                        <div className='font-poppins' dangerouslySetInnerHTML={{__html: editorLetter}}></div>
                    }
                </DialogContent>
            </Dialog>
            {/* Response Dialog */}
            <Dialog open={respDialog} maxWidth={'xs'} fullWidth={true} onClose={()=>setRespDialog(false)} >
                <DialogContent>
                    <p className='fw-bold font-poppins fs-5 px-0'>
                        Server Response
                    </p>
                    <p className='font-poppins fs-6'> 
                        {response}                        
                    </p>
                </DialogContent>
            </Dialog>
            {/* Sending Dialog */}
            <Dialog open={sendingDialog} PaperProps={{
                style: {
                backgroundColor: 'transparent',
                boxShadow: 'none',
                }
            }}
            >
                <DialogContent>
                    <p>
                        <span className='spinner-border text-white'></span>
                    </p>
                </DialogContent>
            </Dialog>
        </Fragment>
    )
}

export default NewsLetter