import * as yup from 'yup'

export const contactSchema = yup.object().shape({
    email: yup.string().email('Invalid Email Address').required(),
    subject: yup.string().required(),
    message: yup.string().required()
})

export const newsletterSchema = yup.object().shape({
    email: yup.string().email('Invalid Email Address').required('Required')
})

export const publicationSchema = yup.object().shape({
    title: yup.string().required(),
    authors: yup.string().required(),
    link: yup.string().url('Invalid Url').required()
})

export const serviceSchema = yup.object().shape({
    type: yup.string().required(),
    url: yup.string().url('Invalid Url').required()
})

export const impactsSchema = yup.object().shape({
    picture: yup.string().required(),
    caption: yup.string().required(),
    participants: yup.string().required(),
    date: yup.string().required()
})